import React, { Component } from 'react';
import styled from "styled-components";
import { connect } from 'react-redux';

// Component
import SubHeaderControl from '../Control/SubHeaderControl';
import PagingControl from '../Control/PagningControl';
import EmptyControl from "../Control/EmptyControl";
import DeviceKios from "../Advertisement/DeviceKios";
// import DeviceTablet from '../Advertisement/DeviceTablet';
import ShowTicket from '../Advertisement/ShowTicket';
// Data
import { getAds, resetAdsReducer } from '../../../../actions/adsAction';
import { advertisementTabArr, emptyData } from '../../../../data/data'
import { color } from '../../../../data/store';
import {officeUtils} from '../../../Utils/OfficeUtils';

const Bound = styled.div`
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: bold;
    display:flex;
    flex:1;
    flex-direction:column;
    overflow:hidden;
    /*min-width:1260px;*/
    .title {
        background-color:${color.whiteFF};
        height: 48px;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;            
        .choise {
            display:flex;
            align-items:center;
            justify-content:center;
            width:120px;
            height:calc(100% - 3px);
            cursor: pointer;
        }
    }
    .ad-container{
        display: flex;
        flex: 1;
        overflow: hidden;  
        padding: 0 40px;
        flex-direction: column; 
        .title-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            p {
                color: ${color.blue_005993};
                font-size: 11px;
                text-transform: uppercase;
                margin: 26px 0 17px 0;
            }
        }
        .item {
            display: flex;
            flex-direction: column;
            overflow:hidden;
            p{
                text-transform: uppercase;
            }
            .scroll{
                overflow:auto;   
                ::-webkit-scrollbar { 
                    display: none; 
                    }
                .item-child {
                  display: grid;
                  grid-template-columns: 24% 24% 24% 24%;
                  /*justify-content: space-evenly;*/
                  justify-content: space-between;
                  grid-gap: 4% 1%;       
                }
                @media screen and (min-width: 1024px) {
                  .item-child {
                    grid-template-columns: 240px 240px 240px 240px;
                  }
                }  
            }
        }
        
    }
`
const limitPerPage = 8
class AdvertisePGDContainer extends Component {
  _isMounted = false;
  state = {
    typeSelected: advertisementTabArr[0].key,
    subHeaderData: advertisementTabArr[0].subHeaderData,
    data: [],
    currentPage: 1,
    startIndex: 0,
    endIndex: 10,
    isAdd: true,
    isCustom: true,
  }

  onGetAds = ({typeAds, pageSize, pageNumber, searchText}) => {
    let atOfficeID = officeUtils.getOfficeID({
      dataLogin: this.props.userReducer.dataLogin,
      generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
    })
    //---
    this.props.getAds(
      atOfficeID, 
      typeAds, 
      pageSize, 
      pageNumber,
      searchText
    );
  }

  setTypeSelected = (tab) => {
    if (tab) {
      const { typeSelected } = this.state
      if (typeSelected !== tab.key) {
        this.onGetAds({
          typeAds: tab.key, 
          pageSize: limitPerPage, 
          pageNumber: 1
        });
      }
      this.setState({
        typeSelected: tab.key,
        subHeaderData: tab.subHeaderData
      })
    }
  }
  pagnationPage() {
    let endIndex = this.state.currentPage * limitPerPage;
    let startIndex = endIndex - limitPerPage;
    this.setState({ startIndex, endIndex })
  }
  onPageChange = (e) => {
    let currentPage = e
    let endIndex = currentPage * limitPerPage;
    let startIndex = endIndex - limitPerPage;
    this.setState({ currentPage, startIndex, endIndex });
    this.onGetAds({
      typeAds: this.state.typeSelected, 
      pageSize: limitPerPage, 
      pageNumber: currentPage
    });
  }

  renderTab() {
    return advertisementTabArr.map((tab, i) => {
      return (
        <div className='choise' key={i}
          style={this.state.typeSelected === tab.key ?
            { color: tab.activeColor, borderBottom: tab.avtiveBorderBottom }
            :
            { color: tab.disActiveColor }}
          onClick={() => this.setTypeSelected(tab)}>
          {tab.label}
        </div>
      )
    })
  }

  componentDidMount() {
    this._isMounted = true;
    const { typeSelected } = this.state
    this._isMounted && this.pagnationPage();
    this._isMounted && this.onGetAds({
        typeAds: typeSelected, 
        pageSize: limitPerPage, 
        pageNumber: 1
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const prevOfficeId =  officeUtils.getOfficeID({
      dataLogin: this.props.userReducer.dataLogin, 
      generalOfficeSelected: prevProps.officeManagementReducer.generalOfficeSelected
    })

    const officeId =  officeUtils.getOfficeID({
      dataLogin: this.props.userReducer.dataLogin, 
      generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
    })
    //---
    if(officeId !== prevOfficeId){
      const { typeSelected } = this.state;
      this.onGetAds({
        typeAds: typeSelected, 
        pageSize: limitPerPage, 
        pageNumber: 1
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { listAds, totalAds, currentPage } = this.props.getAdsReducer;
    let { subHeaderData, typeSelected } = this.state
    let mainTitle =  officeUtils.getOfficeName({
      dataLogin: this.props.userReducer.dataLogin, 
      generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
    })

    return (
      <Bound>
        <div className='title'>
          {this.renderTab()}
        </div>
        <div className='ad-container'>
          <SubHeaderControl
            typeSearch = 'searchAds'
            data={{...subHeaderData, mainTitle: mainTitle}}
            isAdd={this.state.isAdd}
            onReset = { () => this.onGetAds({
                                  typeAds: this.state.typeSelected, 
                                  pageSize: limitPerPage, 
                                  pageNumber: 1,
                                  searchText: ''
                                })
                      }
            searchFilter= {(textSearch) => this.onGetAds({
                              typeAds: this.state.typeSelected, 
                              pageSize: limitPerPage, 
                              pageNumber: 1,
                              searchText: textSearch
                            })
                          }
          />

          {this._isMounted && listAds && listAds.length > 0
            ? (
              <div className="item">
                <div className='title-wrapper'>
                  <p>danh sách quảng cáo</p>
                  {
                    (totalAds > limitPerPage) ?
                      <PagingControl
                        currentPage={currentPage}
                        totalItem={totalAds}
                        limitPerPage={limitPerPage}
                        onChangePage={page => this.onPageChange(page)} /> : null
                  }
                </div>
                <div className='scroll'>
                  <div className="item-child">
                    {
                      // this._isMounted && dataSlice.map((item, i) => {
                      this._isMounted && listAds.map((item, i) => {
                        switch (typeSelected) {
                          case advertisementTabArr[0].key: {
                            return (
                              <DeviceKios
                                isCustom={this.state.isCustom}
                                key={i}
                                keyValue={i}
                                data={item}
                                onShowControl={(offset, from, data) => {
                                  this.props.onShowControl(offset, from, data);
                                }} />
                            )
                          }
                          case advertisementTabArr[1].key: {
                            return (
                              <DeviceKios
                                isCustom={this.state.isCustom}
                                key={i}
                                keyValue={i}
                                data={item}
                                onShowControl={(offset, from, data) => {
                                  this.props.onShowControl(offset, from, data);
                                }} />
                            )
                          }
                          case advertisementTabArr[2].key: {
                            return (
                              <ShowTicket
                                isCustom={this.state.isCustom}
                                key={i}
                                keyValue={i}
                                data={item}
                                onShowControl={(offset, from, data) => {
                                  this.props.onShowControl(offset, from, data);
                                }} />
                            )
                          }
                          default:
                            break;
                        }
                        return null;
                      })
                    }
                  </div>
                </div>
              </div>
            )
            : (<EmptyControl
              // alertContent={alert}
              text_title="Chưa có quảng cáo được cài đặt"
              text="Quảng cáo sẽ được tự động cập nhật khi có thiết lập từ hệ thống"
              content={emptyData.advertisement}
            />)
          }
        </div>
      </Bound>
    );
  }
}
const mapStateToProps = (state) => ({
  userReducer: state.userReducer,
  getAdsReducer: state.getAdsReducer,
  officeManagementReducer: state.officeManagementReducer
})

const mapDispatchToProps = dispatch => ({
  getAds: (officeID, typeAds, pageSize, pageNumber, searchText = undefined) => dispatch(getAds(officeID, typeAds, pageSize, pageNumber, searchText)),
  resetAdsReducer: () => dispatch(resetAdsReducer()),
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AdvertisePGDContainer);