import styled, { keyframes } from 'styled-components'
import { color } from '../../../../../data/store';

const expand = keyframes`
    from {
        height: 0;
        padding: 0;
        border: none;
    }

    to {
        height: auto;
        padding: 10px 20px 25px;
        border: 1px solid #EFEFEF;
    }
`

const WrapTableListUser = styled.div`
    font-family: 'SVN-Gilroy';
    padding: 0 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    

    .grid {
        display: grid;
        align-items: center;
        grid-column-gap: 20px;
        grid-template-columns: 180px 180px 1fr 220px;

        &.col-title {
            margin: 23px 0 8px 0;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 110%;
            color: ${color.blue_005993};

            .result {
                .wrap-note {
                    &:not(:first-child) {
                        margin-left: 19px;
                    }

                    img {
                        width: 12px;
                        height: 12px;
                        margin-right: 2px;
                    }
                }
            }
        }

        &.row-item {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 128%;
            color: #222222;
            min-height: 48px;
            background: #FFFFFF;
            box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
            border-radius: 5px;

            .value {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:not(:first-child) {
                margin-top: 3px;
            }

            .result {
                padding-right: 14px;
                .wrap-note {
                    img {
                        width: 16px;
                        height: 16px;
                    }
                }

                .ic-expand {
                    width: 16px;
                    height: 16px;
                    /* transition: transform 0.3s ease-in-out; */

                    &.expand {
                        transform: rotateZ(90deg);
                    }
                }
            }

            &:hover {
                cursor: pointer;
            }
        }

        & > *:first-child {
            padding-left: 20px;
        }

        .result {
            display: flex;
            justify-content: flex-end;
            
            & > *:not(:first-child) {
                margin-left: 10px;
            }
        }
    }

    .wrap-note {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 110%;
        color: #000000;
        display: flex;
        align-items: center;

        img {
            display: inline-block;
        }
    }

    .wrap-list-users {
        overflow-y: auto;
        max-height: 397px;

        .detail-roles {
            border: 1px solid #EFEFEF;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 10px 20px 25px;
            /* animation: ${expand} 0.5s ease-in-out; */
            overflow: hidden;

            .title-role-group {
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 110%;
                color: ${color.blue_005993};

                &:not(:first-child) {
                    margin-top: 25px;
                }
            }

            ul {
                margin-left: 20px;
                li {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 128%;
                    color: #222222;
                    margin-top: 16px;
                }
            }
        }
    }
`;

export {
    WrapTableListUser
}