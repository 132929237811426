import Axios from 'axios';

import * as types from './types';
import { host } from "../host";
import {
    addLoading,
    removeLoading,
} from "./commonAction";
import catchErrorApi from '../helper/catchErrorApi';
import { isArray } from '../helper/checkValidity';

const apiGetOfficeManagementList = host + "admin/office/offices-management";

export const getOfficeManagementList = ({uid = '', isReset = false}) => async dispatch => {
    dispatch(addLoading())
    //---
    const queryData = JSON.stringify({
        UID: uid
    })
    try {
        const res = await Axios.post(apiGetOfficeManagementList, queryData);
        const { data } = res;
        //---
        let listOffice = []
        if(isArray(data.ListOffice) && data.ListOffice.length > 0){
            listOffice =  data.ListOffice
        }
        //---
        dispatch({
            type: types.GET_OFFICES_MANAGEMENT,
            success: true,
            dataList: listOffice,
            isReset: isReset
        })
    }
    catch (err) {
        catchErrorApi(
            err,
            dispatch,
            types.GET_OFFICES_MANAGEMENT_ERR,
            () => false
        )
    }
    dispatch(removeLoading())
}

export const updateGeneralOfficeSelected = ({dataInfo}) => async dispatch => {
    dispatch({
        type: types.UPDATE_GENARAL_OFFICE_SELECTED,
        dataInfo: dataInfo
    })
}