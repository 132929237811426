import React, { Component } from 'react';
import styled from "styled-components";
import { connect } from 'react-redux';

//Component
import TransactionCounter from './TransactionCounter';
import EmptyControl from '../Control/EmptyControl'
import SubHeaderControl from '../Control/SubHeaderControl'
//Data
import { emptyData, subHeaderData } from '../../../../data/data'
import { dataCity } from "../../../../data/VietNamData/DataCity";
import { dataDistrict } from "../../../../data/VietNamData/DataDistrict";
import { dataWard } from "../../../../data/VietNamData/DataWard";

// action
import { getCounterByOffice, genDesktopKey, genTabletKey, clearKeyGen, clearKeyGenCounter } from '../../../../actions/counterAction';
import { allCounters } from '../../../../data/counterNumber';
import { getAllCounterServices } from '../../../../actions/counterServiceAction';
import {officeUtils} from '../../../Utils/OfficeUtils';
import { ADMIN_OFFICE_ROLE } from '../../../../data/userRole';


const Bound = styled.div`
    display:flex;
    flex:1;
    flex-direction:column;
    overflow:auto;
    padding:0 40px;
`

class TransactionPGDContainer extends Component {
  state = {
    subHeaderData: subHeaderData.transactionRoomPGD,
    alertContent: ''
  }

  componentWillMount() {
    let { subHeaderData } = this.state;
    const { dataLogin } = this.props.userReducer;
    subHeaderData = {
      ...subHeaderData,
      mainTitle: dataLogin.OfficeName,
      subTitle: [
        dataLogin.OfficeAddress,
        dataWard[dataLogin.OfficeWard].name,
        dataDistrict[dataLogin.OfficeDistrict].name,
        dataCity[dataLogin.OfficeProvince].name
      ].join(", ")
    }
    this.setState({ subHeaderData })
  }


  render() {
    let officeId = officeUtils.getOfficeID({
      dataLogin: this.props.userReducer.dataLogin,
      generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
    })
    //---
    let mainTitle = officeUtils.getOfficeName({
      dataLogin: this.props.userReducer.dataLogin,
      generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
    })
    let subTitle = officeUtils.getOfficeAddress({
      dataLogin: this.props.userReducer.dataLogin,
      generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
    })
    //---
    const { total, listCounterNumber } = this.props.counterReducer;
    let elmNumberCounter;
    if (total === allCounters.length) {
      elmNumberCounter = this.props.onShowOutOfLimit
    } else {
      elmNumberCounter = this.props.showPopupAddCounter
    }
    return (
        <Bound>
            <SubHeaderControl
                onAddMoreSubmit={elmNumberCounter}
                // onAddMoreSubmit={this.props.showPopupAddCounter}
                data={{...this.state.subHeaderData, mainTitle: mainTitle, subTitle: subTitle}}
                counterRemain={allCounters.length - listCounterNumber.length}
            />
            {this.props.counterReducer.listCounter.length > 0 ? (
                <TransactionCounter
                    getCounterByOffice={this.props.getCounterByOffice}
                    onShowControl={(offset, from, dataReceive) => {
                        this.props.onShowControl(offset, from, dataReceive)
                    }}
                    onShowDisConnectCounter={(data) =>
                        this.props.onShowDisConnectCounter(data)
                    }
                    data={this.props.counterReducer}
                    officeID={officeId}
                    genDesktopKey={this.props.genDesktopKey}
                    genTabletKey={this.props.genTabletKey}
                    onShowPopupEdit={this.props.onShowPopupEdit}
                    onShowPopupDelete={this.props.onShowPopupDelete}
                    onShowPopupErr={this.props.onShowPopupErr}
                    clearKeyGenCounter={this.props.clearKeyGenCounter}
                />
            ) : (
                <EmptyControl
                    alertContent={this.state.alertContent}
                    widthInput='400px'
                    content={emptyData.counter}
                />
            )}
        </Bound>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const prevOfficeId = officeUtils.getOfficeID({
      dataLogin: this.props.userReducer.dataLogin,
      generalOfficeSelected: prevProps.officeManagementReducer.generalOfficeSelected
    })
    const officeId = officeUtils.getOfficeID({
      dataLogin: this.props.userReducer.dataLogin,
      generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
    })
    //---
    if(!!officeId && officeId !== "" && officeId !== prevOfficeId){
      this.props.getCounterByOffice(officeId, 8, 1)
    }
    //---
    if (prevProps.counterReducer.total !== this.props.counterReducer.total) {
      this.props.getCounterByOffice(officeId, this.props.counterReducer.total, 1, false)
    }
  }

  async componentDidMount() {
    const officeId = officeUtils.getOfficeID({
      dataLogin: this.props.userReducer.dataLogin,
      generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
    })
    //---
    if(!!officeId && officeId !== ""){
      this.props.getCounterByOffice(officeId, 8, 1)
    }
    //---
    this.props.getAllCounterServices({
      uid: this.props.userReducer.dataLogin.UID,
      status: this.props.userReducer.dataLogin.Roles.includes(ADMIN_OFFICE_ROLE)?'A':''
    })
  }

  componentWillUnmount() {
    this.props.clearKeyGen()
  }
  
}
const mapStateToProps = state => ({
  userReducer: state.userReducer,
  counterReducer: state.counterReducer,
  officeManagementReducer: state.officeManagementReducer
})

const mapDispatchToProps = (dispatch) => ({
    getCounterByOffice: (officeId, pageSize, pageNumber, isLoad = true) =>
        dispatch(getCounterByOffice(officeId, pageSize, pageNumber, isLoad)),
    getAllCounterServices: ({uid, status}) =>
        dispatch(getAllCounterServices({uid, status})),
    genDesktopKey: (counterId, officeId) =>
        dispatch(genDesktopKey(counterId, officeId)),
    genTabletKey: (counterId, officeId) =>
        dispatch(genTabletKey(counterId, officeId)),
    clearKeyGen: () => dispatch(clearKeyGen()),
    clearKeyGenCounter: (counterId, key) =>
        dispatch(clearKeyGenCounter(counterId, key)),
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwarRef: true })(TransactionPGDContainer);