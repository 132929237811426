import React, { useState, Fragment } from 'react'

// styles
import { WrapTableListUser } from './index.styles'

// images
import ic_warning from '../../../../../images/ic_warning.svg'
import ic_notification_success from '../../../../../images/ic_notification_success.svg'
import ic_next from '../../../../../images/ic_next.svg'
import * as userRole from '../../../../../data/userRole'
import { Tooltip } from '@mui/material'

const mapRoleToText = {
    [userRole.ADMIN_OFFICE_ROLE]: 'Quản trị HT tại CN',
    [userRole.TELLER]: 'GDV CN',
    [userRole.OFFINSUPERVISOR]: 'Ban giám đốc CN',
    [userRole.OFFEXSUPERVISOR]: 'KSV CN',
    [userRole.INBIOCOLLECTOR]: 'Đăng ký STH cho cán bộ CN',
    [userRole.RPW]: 'Xem báo cáo',
    [userRole.IFW]: 'Vấn tin',
    [userRole.IFW_SID]: 'Vấn tin theo giấy tờ tùy thân',
    [userRole.IFW_SC]: 'Vấn tin theo số CIF',
    [userRole.IFW_DUP]: 'Vấn tin trùng STH',
    [userRole.IFW_SBB]: 'Vấn tin theo hình ảnh STH',
    [userRole.CCD]: 'Điều phối khách hàng tại sảnh giao dịch',
    [userRole.CCD_GQN]: 'Lấy số xếp hàng',
    [userRole.CCD_CCS]: 'Tư vấn khách hàng',
    [userRole.CCD_CCT]: 'Chuyển quầy',
}

const TableListUser = ({ noteError, noteSuccess, listUser, objChildReport }) => {
    const [activeUsername, setActiveUsername] = useState('');

    const _handleExpandRole = (username) => {
        if (activeUsername === username) {
            setActiveUsername('')
            return;
        }
        setActiveUsername(username)
    }

    return (
        <WrapTableListUser>
            <div className="grid col-title">
                <div>UserAD</div>
                <div>Họ tên</div>
                <div>CN/PGD</div>
                <div className="result">
                    <div className="wrap-note">
                        <img src={ic_warning} alt="warning" />
                        {noteError}
                    </div>
                    <div className="wrap-note">
                        <img src={ic_notification_success} alt="success" />
                        {noteSuccess}
                    </div>
                </div>
            </div>
            <div className="wrap-list-users">
                {
                    listUser.map(({
                        username,
                        name,
                        branchCode,
                        lstRoles,
                        reportPermission,
                        infosPermission,
                        ccdPermission,
                        error
                    }, index) => (
                        <Fragment key={index}>
                            <div className="grid row-item" onClick={() => _handleExpandRole(username)}>
                                <Tooltip title={username}>
                                    <div className='value'>{username}</div>
                                </Tooltip>
                                <Tooltip title={name}>
                                    <div className='value'>{name}</div>
                                </Tooltip>
                                <Tooltip title={branchCode}>
                                    <div className='value'>{branchCode}</div>
                                </Tooltip>
                                <div className="result">
                                    <div>{`${lstRoles.length} phân quyền`}</div>
                                    {
                                        !!error &&
                                        // <div className="wrap-note" title={error}>
                                        //     <img src={ic_warning} alt="warning" />
                                        // </div>
                                        <Tooltip title={error}>
                                            <div className="wrap-note">
                                                <img src={ic_warning} alt="warning" />
                                            </div>
                                        </Tooltip>
                                    }
                                    {
                                        !error &&
                                        <div className="wrap-note">
                                            <img src={ic_notification_success} alt="success" />
                                        </div>
                                    }
                                    <img className={`ic-expand${activeUsername === username ? ' expand' : ''}`} src={ic_next} alt="expand-collapse" />
                                </div>
                            </div>
                            {
                                activeUsername === username &&
                                <div className="detail-roles">
                                    <div className="title-role-group">Phân quyền chính</div>
                                    <ul>
                                        {lstRoles.map((role) => (
                                            <li key={role}>{mapRoleToText[role]}</li>
                                        ))}
                                    </ul>
                                    {
                                        !!infosPermission.length &&
                                        <>
                                            <div className="title-role-group">Chi tiết phân quyền vấn tin</div>
                                            <ul>
                                                {infosPermission.map((role) => (
                                                    <li key={role}>{mapRoleToText[role]}</li>
                                                ))}
                                            </ul>
                                        </>
                                    }
                                    {
                                        !!reportPermission.length &&
                                        <>
                                            <div className="title-role-group">Chi tiết phân quyền báo cáo</div>
                                            <ul>
                                                {reportPermission.map((role) => (
                                                    <li key={role}>{`${role} - ${objChildReport[role].Name}`}</li>
                                                ))}
                                            </ul>
                                        </>
                                    }
                                    {
                                        !!ccdPermission.length &&
                                        <>
                                            <div className="title-role-group">Chi tiết phân quyền điều phối khách hàng tại sảnh giao dịch</div>
                                            <ul>
                                                {ccdPermission.map((role) => (
                                                    <li key={role}>{mapRoleToText[role]}</li>
                                                ))}
                                            </ul>
                                        </>
                                    }
                                </div>
                            }
                        </Fragment>
                    ))
                }
            </div>
        </WrapTableListUser>
    )
}

export default TableListUser
