import packageJson from '../package.json';

const hostConfigs = {
    // dev: 'http://localhost:7000/api/',
    dev: 'https://vtb1-admin.wee.vn/api/',
    uat: 'https://uatkioskadmin.vietinbank.vn/api/',
    ppe: 'https://prekioskadmins.vietinbank.vn/api/',
    product: 'https://sdbadmin.vietinbank.vn/api/',
    wee: 'https://vtb-admin.wee.vn/api/'
}

const webSocketUrlConfigs = {
    // dev: 'wss://io-dev.wee.vn/ws/connection/websocket',
    dev: 'wss://vtb1-admin.wee.vn/ws/connection/websocket',
    uat: 'wss://uatkioskadmin.vietinbank.vn/ws/connection/websocket',
    ppe: 'wss://prekioskadmins.vietinbank.vn/ws/connection/websocket',
    product: 'wss://sdbadmin.vietinbank.vn/ws/connection/websocket',
    wee: 'wss://vtb-admin.wee.vn/ws/connection/websocket'
}

const host = process.env.REACT_APP_BUILD_TYPE
    ? hostConfigs[process.env.REACT_APP_BUILD_TYPE]
    : hostConfigs.dev
const webSocketUrl = process.env.REACT_APP_BUILD_TYPE
    ? webSocketUrlConfigs[process.env.REACT_APP_BUILD_TYPE]
    : webSocketUrlConfigs.dev

console.log(`build DEV: ${packageJson.version}`)
export { host, webSocketUrl }
// vtb1-admin.wee.vn_v1.3.11-01112024-1048
