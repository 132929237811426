import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
// Component
import PagingControl from '../Control/PagningControl'
import EmptyControl from '../Control/EmptyControl'
import DeviceKiosItem from './DeviceKiosItem'
import DeviceLCDItem from './DeviceLCDItem'
// Data
import { TrandingRoomTabArr, emptyData } from '../../../../data/data'
import {
    getAllKiosk,
    generateKeyConnectKiosk,
    clearKeyGenKiosk,
} from '../../../../actions/kioskAction'
import { getAllLCD, generateKeyConnectLCD, clearKeyGenLcd } from '../../../../actions/LCDAction'
import { color } from '../../../../data/store'
import {officeUtils} from '../../../Utils/OfficeUtils'
import SubHeaderControlV2 from '../Control/SubHeaderControlV2'

const Bound = styled.div`
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    .title {
        background-color: ${color.whiteFF};
        height: 18px;
        display: flex;
        flex-direction: row;
        padding-left: 20px;
        text-align: center;
        padding-bottom: 10px;
        .tab {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: calc(100% - 3px);
            padding-bottom: 10px;
            cursor: pointer;
        }
    }
    .ad-container {
        display: flex;
        flex: 1;
        overflow: hidden;
        padding: 0 40px;
        flex-direction: column;
        .title-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            p {
                color: ${color.blue_005993};
                font-size: 11px;
                text-transform: uppercase;
                margin: 26px 0 17px 0;
            }
        }
        .item {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            & > p {
                text-transform: uppercase;
            }
            .scroll {
                overflow: auto;
                &::-webkit-scrollbar {
                    width: 0px;
                }
                .item-child {
                    display: grid;
                    width: 100%;
                    grid-template-columns: 20% 20% 20% 20%;
                    /*justify-content: space-evenly;*/
                    justify-content: space-between;
                    grid-gap: 13px 11px;

                    ::-webkit-scrollbar {
                        display: none;
                    }
                }
                @media screen and (min-width: 1024px) {
                    .item-child {
                        grid-template-columns: repeat(4, 280px);
                        grid-gap: 13px 11px;
                    }
                }
            }
        }
    }
`
const limitPerPage = 8
class TrandingRoomDevice extends Component {
    _isMounted = false
    state = {
        tradingTab: [],
        typeSelected: TrandingRoomTabArr[0].key,
        subHeaderData: TrandingRoomTabArr[0].subHeaderData,
        hasKioskDeviceConfig: true,
        hasLcdDeviceConfig: true,
        data: this.props.data,
        currentPage: 1,
        startIndex: 0,
        endIndex: 10,
        alertContent: '',
    }

    componentWillMount() {
        this.resetUI()
    }

    resetUI() {
        const dataLogin = this.props.userReducer.dataLogin
        let dataInfo = officeUtils.getOfficeInfo({
            dataLogin: dataLogin,
            generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
        })
        //---
        let tradingTab = JSON.parse(JSON.stringify(TrandingRoomTabArr))
        let newTradingTabList = []
        tradingTab.forEach((item, index) => {
            if (item.key === "LCD" && dataInfo.HasLcdDeviceConfig) {
                newTradingTabList.push(item)
            }
            if (item.key === "KIOSK" && dataInfo.HasKioskDeviceConfig) {
                newTradingTabList.push(item)
            }
        })

        tradingTab = newTradingTabList.map((item) => ({
            ...item,
            subHeaderData: {
                ...item.subHeaderData,
                mainTitle: dataInfo.OfficeName??dataInfo.Name,
                subTitle: officeUtils.getOfficeAddress({
                    dataLogin: dataLogin,
                    generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
                })
            },
        }))

        const tradingTabSelected = tradingTab.length === 0? -1 : tradingTab[0].key
        const subHeaderData = tradingTabSelected !== -1? tradingTab[0].subHeaderData : undefined

        this.setState({
            tradingTab,
            typeSelected: tradingTabSelected,
            subHeaderData: subHeaderData,
            hasKioskDeviceConfig: dataInfo.HasKioskDeviceConfig,
            hasLCDDeviceConfig: dataInfo.HasLCDDeviceConfig
        })

        return tradingTabSelected;
    }

    componentDidMount() {
        this._isMounted = true
        const officeId = officeUtils.getOfficeID({
            dataLogin: this.props.userReducer.dataLogin, 
            generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
        })
        //---
        this.pagnationPage()
        if(officeId && this.state.typeSelected !== -1){
            if (this.state.typeSelected === TrandingRoomTabArr[0].key) {
                this.props.getAllKiosk(
                    officeId,
                    8,
                    1
                )
            } else {
                this.props.getAllLCD(
                    officeId,
                    8,
                    1
                )
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const dataLogin = this.props.userReducer.dataLogin
        const generalOfficeSelected = this.props.officeManagementReducer.generalOfficeSelected
        if (
            prevProps.kioskReducer.kioskInfo !==
            this.props.kioskReducer.kioskInfo
        ) {
            this.setState({ currentPage: 1 })
        } else if (
            prevProps.LCDReducer.LCDInfo !== this.props.LCDReducer.LCDInfo
        ) {
            this.setState({ currentPage: 1 })
        }
        //---
        const prevOfficeId = officeUtils.getOfficeID({
            dataLogin: dataLogin, 
            generalOfficeSelected: prevProps.officeManagementReducer.generalOfficeSelected
        })
        const officeId = officeUtils.getOfficeID({
            dataLogin: dataLogin, 
            generalOfficeSelected: generalOfficeSelected
        })
        if(officeId !== prevOfficeId){
            const tradingTabSelected = this.resetUI()
            if(tradingTabSelected !== -1){
                if(tradingTabSelected === TrandingRoomTabArr[0].key){
                    this.props.getAllKiosk(
                        officeId,
                        8,
                        1
                    )
                } else {
                    this.props.getAllLCD(
                        officeId,
                        8,
                        1
                    )
                }
            }
            //---
            // let dataInfo = officeUtils.getOfficeInfo({
            //     dataLogin: dataLogin,
            //     generalOfficeSelected: generalOfficeSelected
            // })
            // //---
            // if(this.state.typeSelected === -1){
            //     if(dataInfo.HasKioskDeviceConfig){
            //         this.props.getAllKiosk(
            //             officeId,
            //             8,
            //             1
            //         )
            //     } else if (dataInfo.HasLcdDeviceConfig) {
            //         this.props.getAllLCD(
            //             officeId,
            //             8,
            //             1
            //         )
            //     }
            // } else {
            //     if(this.state.typeSelected === TrandingRoomTabArr[0].key){
            //         this.props.getAllKiosk(
            //             officeId,
            //             8,
            //             1
            //         )
            //     } else {
            //         this.props.getAllLCD(
            //             officeId,
            //             8,
            //             1
            //         )
            //     }
            // }
        }
    }

    setTypeSelected = async (tab) => {
        if (tab) {
            const { typeSelected } = this.state
            this.setState(
                {
                    typeSelected: tab.key,
                    subHeaderData: tab.subHeaderData,
                    currentPage: 1,
                },
                async () => {
                    const officeId = officeUtils.getOfficeID({
                        dataLogin: this.props.userReducer.dataLogin, 
                        generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
                    })
                    //---
                    if (typeSelected === TrandingRoomTabArr[0].key) {
                        (this._isMounted) &&
                            this.props.getAllLCD(
                                officeId,
                                8,
                                1
                            )
                    } else {
                        (this._isMounted) &&
                            this.props.getAllKiosk(
                                officeId,
                                8,
                                1
                            )
                    }
                }
            )
        }
    }
    pagnationPage() {
        let endIndex = this.state.currentPage * limitPerPage
        let startIndex = endIndex - limitPerPage
        this.setState({ startIndex, endIndex })
    }
    onPageChange = (e) => {
        let currentPage = e
        let endIndex = currentPage * limitPerPage
        let startIndex = endIndex - limitPerPage
        this.setState({ currentPage, startIndex, endIndex })
        //---
        const officeId = officeUtils.getOfficeID({
            dataLogin: this.props.userReducer.dataLogin, 
            generalOfficeSelected: this.props.officeManagementReducer.generalOfficeSelected
          })
        //---
        if (this.state.typeSelected === TrandingRoomTabArr[0].key) {
            this._isMounted &&
                this.props.getAllKiosk(
                    officeId,
                    8,
                    currentPage
                )
        } else {
            this._isMounted &&
                this.props.getAllLCD(
                    officeId,
                    8,
                    currentPage
                )
        }
    }
    showPopupAddAdvert = () => {
        const { typeSelected } = this.state
        switch (typeSelected) {
            case TrandingRoomTabArr[0].key: {
                this.props.showAddTrandingRoomKioskPopup(this.state.currentPage)
                break
            }
            case TrandingRoomTabArr[1].key: {
                this.props.showAddTrandingRoomLCDPopup()
                break
            }
            default:
                break
        }
    }
    renderTab() {
        return this.state.tradingTab.map((tab, i) => {
            return (
                <div
                    className="tab"
                    key={i}
                    style={
                        this.state.typeSelected === tab.key
                            ? {
                                color: tab.activeColor,
                                borderBottom: tab.avtiveBorderBottom,
                            }
                            : { color: tab.disActiveColor }
                    }
                    onClick={() => this.setTypeSelected(tab)}
                >
                    {tab.label}
                </div>
            )
        })
    }

    renderTitleList = () => {
        return TrandingRoomTabArr.find(
            (tab) => tab.key === this.state.typeSelected
        ).title
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        let { subHeaderData, typeSelected } = this.state
        //---
        const { listKiosk, total: totalKiosk, currentPage: currentPageKiosk } = this.props.kioskReducer
        const { listLCD, total: totalLcd, currentPage: currentPageLcd } = this.props.LCDReducer
        let totalPage
        if (this.state.typeSelected === TrandingRoomTabArr[0].key) {
            totalPage = totalKiosk
        } else {
            totalPage = totalLcd
        }

        return (
            <Bound>
                {
                    typeSelected === -1
                    ?   <EmptyControl
                            alertContent={''}
                            widthInput='90%'
                            content={`Chi nhánh/PGD của bạn chọn không hỗ trợ tính năng này. \n Vui lòng liên hệ trụ sở chính để nâng cấp`}
                        />
                    : <>
                        <div className="title">{this.renderTab()}</div>
                        <div className="ad-container">
                            <SubHeaderControlV2
                                data={subHeaderData}
                                onAddMoreSubmit={this.showPopupAddAdvert}
                            />
                            {this._isMounted && totalPage > 0 ? (
                                <div className="item">
                                    <div className="title-wrapper">
                                        <p>{this.renderTitleList()}</p>
                                        {totalPage > limitPerPage ? (
                                            <PagingControl
                                                currentPage={
                                                    typeSelected ===
                                                        TrandingRoomTabArr[0].key
                                                        ? currentPageKiosk
                                                        : currentPageLcd
                                                }
                                                totalItem={totalPage}
                                                limitPerPage={limitPerPage}
                                                onChangePage={(page) =>
                                                    this.onPageChange(page)
                                                }
                                            />
                                        ) : null}
                                    </div>
                                    <div className="scroll">
                                        <div className="item-child">
                                            {
                                                this._isMounted &&
                                                listKiosk.map((item, i) => {
                                                    switch (typeSelected) {
                                                        case TrandingRoomTabArr[0]
                                                            .key: {
                                                                return (
                                                                    <DeviceKiosItem
                                                                        clearKeyGenKiosk={
                                                                            this.props
                                                                                .clearKeyGenKiosk
                                                                        }
                                                                        currentPage={
                                                                            currentPageKiosk
                                                                        }
                                                                        key={i}
                                                                        keyValue={i}
                                                                        data={item}
                                                                        dataGenkey={
                                                                            item.dataGenKey ? item.dataGenKey : null
                                                                        }
                                                                        onShowControl={(
                                                                            offset,
                                                                            from,
                                                                            data
                                                                        ) => {
                                                                            this.props.onShowControl(
                                                                                offset,
                                                                                from,
                                                                                data
                                                                            )
                                                                        }}
                                                                        onShowPopupEditKiosk={(
                                                                            data
                                                                        ) =>
                                                                            this.props.onShowPopupEditKiosk(
                                                                                data
                                                                            )
                                                                        }
                                                                        generateKeyConnectKiosk={
                                                                            this.props
                                                                                .generateKeyConnectKiosk
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        default:
                                                            break
                                                    }
                                                    return null
                                                })
                                            }
                                            {(() => {
                                                return (
                                                    this._isMounted &&
                                                    listLCD.map((item, i) => {
                                                        switch (typeSelected) {
                                                            case TrandingRoomTabArr[1]
                                                                .key: {
                                                                    return (
                                                                        <DeviceLCDItem
                                                                            clearKeyGenLcd={this.props.clearKeyGenLcd}
                                                                            currentPage={
                                                                                currentPageKiosk
                                                                            }
                                                                            key={i}
                                                                            keyValue={i}
                                                                            data={item}
                                                                            dataGenkey={
                                                                                item.dataGenKey ? item.dataGenKey : {}
                                                                                //this.props.LCDReducer.dataGenKey
                                                                            }
                                                                            onShowPopupEditLCD={(
                                                                                data
                                                                            ) =>
                                                                                this.props.onShowPopupEditLCD(
                                                                                    data
                                                                                )
                                                                            }
                                                                            onShowControl={(
                                                                                offset,
                                                                                from,
                                                                                data
                                                                            ) => {
                                                                                this.props.onShowControl(
                                                                                    offset,
                                                                                    from,
                                                                                    data,
                                                                                    this
                                                                                        .state
                                                                                        .currentPage
                                                                                )
                                                                            }}
                                                                            generateKeyConnectLCD={
                                                                                this.props
                                                                                    .generateKeyConnectLCD
                                                                            }
                                                                        />
                                                                    )
                                                                }
                                                            default:
                                                                break
                                                        }
                                                        return null
                                                    })
                                                )
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <EmptyControl
                                    alertContent={this.state.alertContent}
                                    widthInput='400px'
                                    content={emptyData.deviceKiosk}
                                />
                            )}
                        </div>
                      </>
                }
               
            </Bound>
        )
    }
}

const mapStateToProps = (state) => ({
    kioskReducer: state.kioskReducer,
    userReducer: state.userReducer,
    LCDReducer: state.LCDReducer,
    officeManagementReducer: state.officeManagementReducer
})

const mapDispatchToProps = (dispatch) => ({
    getAllKiosk: (idOffice, pageSize, pageNumber) =>
        dispatch(getAllKiosk(idOffice, pageSize, pageNumber)),
    getAllLCD: (idOffice, pageSize, pageNumber) =>
        dispatch(getAllLCD(idOffice, pageSize, pageNumber)),
    generateKeyConnectKiosk: (idOffice, idKiosk) =>
        dispatch(generateKeyConnectKiosk(idOffice, idKiosk)),
    generateKeyConnectLCD: (idOffice, idLCD) =>
        dispatch(generateKeyConnectLCD(idOffice, idLCD)),
    clearKeyGenKiosk: (kioskId, kioskName, key) =>
        dispatch(clearKeyGenKiosk(kioskId, kioskName, key)),
    clearKeyGenLcd: (lcdId, lcdName, key) =>
        dispatch(clearKeyGenLcd(lcdId, lcdName, key)),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(TrandingRoomDevice)
