import Axios from 'axios';

import * as types from './types';
import { host } from "../host";
// import { resetStore } from './userAction';
// import { getInfoImageOrVideo } from '../tools';
import {
  addLoading,
  removeLoading,
} from "./commonAction";
import catchErrorApi from '../helper/catchErrorApi';
import { isArray } from '../helper/checkValidity';

const apiGetAllCounterServices = host + "admin/counter-services/getAll"
const apiGetCounterServiceConfig = host + "admin/counter-services/get-config-info";
//---
const apiAddGroupCounterService = host + "admin/counter-services-group/create";
const apiUpdateGroupCounterService = host + "admin/counter-services-group/update";
//---
const apiAddCounterService = host + "admin/counter-services/create";
const apiUpdateCounterService = host + "admin/counter-services/update";

/**
 * 
 * @param {string*} userId id of user was loged in to web
 */
export const getAllCounterServices = ({uid, status = "", onCompletedFunc}) => async dispatch => {
    const queryDataFormat = JSON.stringify({
      UID: uid,
      Status: status
    })
    //---
    addLoading()
    //---
    try {
      const res = await Axios.post(apiGetAllCounterServices, queryDataFormat);
    //   const res = {
    //     data: {
    //         "Code": 0,
    //         "Message": "",
    //         "PersonCustomer": [
    //             {
    //                 "ID": "6661383d87ff9fec8f9b908e",
    //                 "Name": "Tài khoản và thẻ",
    //                 "GroupCode": "KHCN01",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.597Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.597Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66613e3387ff9fec8f9b90a6",
    //                         "Name": "Mở tài khoản thanh toán",
    //                         "ServiceType": "GDV_1941",
    //                         "Description": "Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán - Mở tài khoản thanh toán",
    //                         "Status": "A",
    //                         "ParentID": "6661383d87ff9fec8f9b908e",
    //                         "GroupCode": "KHCN01",
    //                         "CreateAt": "2024-09-04T07:09:30.686Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.687Z"
    //                     },
    //                     {
    //                         "ID": "66613ee687ff9fec8f9b90a7",
    //                         "Name": "Dịch vụ thẻ",
    //                         "ServiceType": "GDV_2621",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661383d87ff9fec8f9b908e",
    //                         "GroupCode": "KHCN01",
    //                         "CreateAt": "2024-09-04T07:09:30.688Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.688Z"
    //                     },
    //                     {
    //                         "ID": "66613fd087ff9fec8f9b90a8",
    //                         "Name": "Thay đổi thông tin KH",
    //                         "ServiceType": "GDV_1901",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661383d87ff9fec8f9b908e",
    //                         "GroupCode": "KHCN01",
    //                         "CreateAt": "2024-09-04T07:09:30.689Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.689Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661389c87ff9fec8f9b908f",
    //                 "Name": "Nộp/ rút tiền mặt",
    //                 "GroupCode": "KHCN02",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.598Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.598Z",
    //                 "Children": [
    //                     {
    //                         "ID": "6661424a87ff9fec8f9b90a9",
    //                         "Name": "Nộp tiền mặt vào tài khoản",
    //                         "ServiceType": "GDV_0941",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661389c87ff9fec8f9b908f",
    //                         "GroupCode": "KHCN02",
    //                         "CreateAt": "2024-09-04T07:09:30.689Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.689Z"
    //                     },
    //                     {
    //                         "ID": "666142fa87ff9fec8f9b90aa",
    //                         "Name": "Rút tiền mặt từ tài khoản",
    //                         "ServiceType": "GDV_1061",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661389c87ff9fec8f9b908f",
    //                         "GroupCode": "KHCN02",
    //                         "CreateAt": "2024-09-04T07:09:30.689Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.689Z"
    //                     },
    //                     {
    //                         "ID": "6661424a87ff9fec8f9b90a9",
    //                         "Name": "Nộp tiền mặt vào tài khoản",
    //                         "ServiceType": "GDV_0941",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661389c87ff9fec8f9b908f",
    //                         "GroupCode": "KHCN02",
    //                         "CreateAt": "2024-09-04T07:09:30.689Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.689Z"
    //                     },
    //                     {
    //                         "ID": "666142fa87ff9fec8f9b90aa",
    //                         "Name": "Rút tiền mặt từ tài khoản",
    //                         "ServiceType": "GDV_1061",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661389c87ff9fec8f9b908f",
    //                         "GroupCode": "KHCN02",
    //                         "CreateAt": "2024-09-04T07:09:30.689Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.689Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "666138ac87ff9fec8f9b9090",
    //                 "Name": "Thanh toán hóa đơn",
    //                 "GroupCode": "KHCN03",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "6661434f87ff9fec8f9b90ab",
    //                         "Name": "Thanh toán hóa đơn bằng tiền mặt",
    //                         "ServiceType": "GDV_0421",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138ac87ff9fec8f9b9090",
    //                         "GroupCode": "KHCN03",
    //                         "CreateAt": "2024-09-04T07:09:30.689Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.689Z"
    //                     },
    //                     {
    //                         "ID": "666143b287ff9fec8f9b90ac",
    //                         "Name": "Thanh toán hóa đơn bằng chuyển khoản",
    //                         "ServiceType": "GDV_0431",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138ac87ff9fec8f9b9090",
    //                         "GroupCode": "KHCN03",
    //                         "CreateAt": "2024-09-04T07:09:30.689Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.689Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "666138cd87ff9fec8f9b9091",
    //                 "Name": "Thu NSNN",
    //                 "GroupCode": "KHCN04",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "666143ed87ff9fec8f9b90ad",
    //                         "Name": "Nộp thuế tiền mặt",
    //                         "ServiceType": "GDV_0231",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138cd87ff9fec8f9b9091",
    //                         "GroupCode": "KHCN04",
    //                         "CreateAt": "2024-09-04T07:09:30.69Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.69Z"
    //                     },
    //                     {
    //                         "ID": "6661440e87ff9fec8f9b90ae",
    //                         "Name": "Nộp phạt tiền mặt",
    //                         "ServiceType": "GDV_0271",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138cd87ff9fec8f9b9091",
    //                         "GroupCode": "KHCN04",
    //                         "CreateAt": "2024-09-04T07:09:30.69Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.69Z"
    //                     },
    //                     {
    //                         "ID": "6661593587ff9fec8f9b90af",
    //                         "Name": "Nộp thuế từ tài khoản",
    //                         "ServiceType": "GDV_0241",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138cd87ff9fec8f9b9091",
    //                         "GroupCode": "KHCN04",
    //                         "CreateAt": "2024-09-04T07:09:30.69Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.69Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "666138db87ff9fec8f9b9092",
    //                 "Name": "Chuyển tiền",
    //                 "GroupCode": "KHCN05",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "666159e987ff9fec8f9b90b0",
    //                         "Name": "Chuyển khoản",
    //                         "ServiceType": "GDV_1131",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138db87ff9fec8f9b9092",
    //                         "GroupCode": "KHCN05",
    //                         "CreateAt": "2024-09-04T07:09:30.69Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.69Z"
    //                     },
    //                     {
    //                         "ID": "66615a4587ff9fec8f9b90b1",
    //                         "Name": "Chuyển tiền ngoài hệ thống từ TK",
    //                         "ServiceType": "GDV_0021",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138db87ff9fec8f9b9092",
    //                         "GroupCode": "KHCN05",
    //                         "CreateAt": "2024-09-04T07:09:30.69Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.69Z"
    //                     },
    //                     {
    //                         "ID": "66615af887ff9fec8f9b90b2",
    //                         "Name": "Chuyển tiền ngoài hệ thống đi từ tiền mặt",
    //                         "ServiceType": "GDV_0011",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138db87ff9fec8f9b9092",
    //                         "GroupCode": "KHCN05",
    //                         "CreateAt": "2024-09-04T07:09:30.691Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.691Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "666138e887ff9fec8f9b9093",
    //                 "Name": "Tiền gửi",
    //                 "GroupCode": "KHCN06",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615b9a87ff9fec8f9b90b3",
    //                         "Name": "Gửi tiết kiệm từ tiền mặt",
    //                         "ServiceType": "GDV_1971",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138e887ff9fec8f9b9093",
    //                         "GroupCode": "KHCN06",
    //                         "CreateAt": "2024-09-04T07:09:30.691Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.691Z"
    //                     },
    //                     {
    //                         "ID": "66615bbd87ff9fec8f9b90b4",
    //                         "Name": "Gửi tiết kiệm từ tài khoản",
    //                         "ServiceType": "GDV_1141",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138e887ff9fec8f9b9093",
    //                         "GroupCode": "KHCN06",
    //                         "CreateAt": "2024-09-04T07:09:30.691Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.691Z"
    //                     },
    //                     {
    //                         "ID": "66615bdd87ff9fec8f9b90b5",
    //                         "Name": "Rút tiết kiệm",
    //                         "ServiceType": "GDV_0761",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138e887ff9fec8f9b9093",
    //                         "GroupCode": "KHCN06",
    //                         "CreateAt": "2024-09-04T07:09:30.691Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.691Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "666138f687ff9fec8f9b9094",
    //                 "Name": "Tiền vay",
    //                 "GroupCode": "KHCN07",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615c3887ff9fec8f9b90b6",
    //                         "Name": "Trả nợ vay từ tiền mặt",
    //                         "ServiceType": "GDV_1021",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138f687ff9fec8f9b9094",
    //                         "GroupCode": "KHCN07",
    //                         "CreateAt": "2024-09-04T07:09:30.691Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.691Z"
    //                     },
    //                     {
    //                         "ID": "66615c5b87ff9fec8f9b90b7",
    //                         "Name": "Trả nợ vay từ tài khoản",
    //                         "ServiceType": "GDV_1031",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "666138f687ff9fec8f9b9094",
    //                         "GroupCode": "KHCN07",
    //                         "CreateAt": "2024-09-04T07:09:30.692Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.692Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661390687ff9fec8f9b9095",
    //                 "Name": "Đăng ký dịch vụ",
    //                 "GroupCode": "KHCN08",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615c9287ff9fec8f9b90b8",
    //                         "Name": "Dịch vụ NHĐT",
    //                         "ServiceType": "GDV_2141",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661390687ff9fec8f9b9095",
    //                         "GroupCode": "KHCN08",
    //                         "CreateAt": "2024-09-04T07:09:30.692Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.692Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661391887ff9fec8f9b9096",
    //                 "Name": "Ngoại tệ",
    //                 "GroupCode": "KHCN09",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615cb287ff9fec8f9b90b9",
    //                         "Name": "Dịch vụ ngoại tệ",
    //                         "ServiceType": "GDV_0541",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661391887ff9fec8f9b9096",
    //                         "GroupCode": "KHCN09",
    //                         "CreateAt": "2024-09-04T07:09:30.692Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.692Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661393987ff9fec8f9b9097",
    //                 "Name": "Dịch vụ khác",
    //                 "GroupCode": "KHCN10",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615cd487ff9fec8f9b90ba",
    //                         "Name": "Dịch vụ khác",
    //                         "ServiceType": "GDV_0001",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661393987ff9fec8f9b9097",
    //                         "GroupCode": "KHCN10",
    //                         "CreateAt": "2024-09-04T07:09:30.692Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.692Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "66d7dcf38ff6a5b7ee405dd4",
    //                 "Name": "phuong tiep tục test",
    //                 "GroupCode": "KHCN11",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.6Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.6Z",
    //                 "Children": null
    //             }
    //         ],
    //         "CorpCustomer": [
    //             {
    //                 "ID": "6661394b87ff9fec8f9b9098",
    //                 "Name": "Tài khoản và thẻ",
    //                 "GroupCode": "KHDN01",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615d9087ff9fec8f9b90bb",
    //                         "Name": "Mở tài khoản thanh toán",
    //                         "ServiceType": "GDV_1992",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661394b87ff9fec8f9b9098",
    //                         "GroupCode": "KHDN01",
    //                         "CreateAt": "2024-09-04T07:09:30.692Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.692Z"
    //                     },
    //                     {
    //                         "ID": "66615d9087ff9fec8f9b90bc",
    //                         "Name": "Thay đổi thông tin KH",
    //                         "ServiceType": "GDV_1912",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661394b87ff9fec8f9b9098",
    //                         "GroupCode": "KHDN01",
    //                         "CreateAt": "2024-09-04T07:09:30.693Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.693Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661395887ff9fec8f9b9099",
    //                 "Name": "Nộp/ rút tiền mặt",
    //                 "GroupCode": "KHDN02",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615df087ff9fec8f9b90bd",
    //                         "Name": "Nộp tiền mặt vào tài khoản",
    //                         "ServiceType": "GDV_0942",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661395887ff9fec8f9b9099",
    //                         "GroupCode": "KHDN02",
    //                         "CreateAt": "2024-09-04T07:09:30.693Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.693Z"
    //                     },
    //                     {
    //                         "ID": "66615df087ff9fec8f9b90be",
    //                         "Name": "Rút tiền mặt từ tài khoản",
    //                         "ServiceType": "GDV_1062",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661395887ff9fec8f9b9099",
    //                         "GroupCode": "KHDN02",
    //                         "CreateAt": "2024-09-04T07:09:30.693Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.693Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661396787ff9fec8f9b909a",
    //                 "Name": "Thu NSNN",
    //                 "GroupCode": "KHDN03",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615e6987ff9fec8f9b90bf",
    //                         "Name": "Nộp thuế từ tài khoản",
    //                         "ServiceType": "GDV_0242",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661396787ff9fec8f9b909a",
    //                         "GroupCode": "KHDN03",
    //                         "CreateAt": "2024-09-04T07:09:30.693Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.693Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661397387ff9fec8f9b909b",
    //                 "Name": "Chuyển tiền",
    //                 "GroupCode": "KHDN04",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.599Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.599Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615ee287ff9fec8f9b90c0",
    //                         "Name": "Chuyển khoản",
    //                         "ServiceType": "GDV_1132",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661397387ff9fec8f9b909b",
    //                         "GroupCode": "KHDN04",
    //                         "CreateAt": "2024-09-04T07:09:30.693Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.693Z"
    //                     },
    //                     {
    //                         "ID": "66615f0c87ff9fec8f9b90c1",
    //                         "Name": "Chuyển tiền ngoài hệ thống từ TK",
    //                         "ServiceType": "GDV_0022",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661397387ff9fec8f9b909b",
    //                         "GroupCode": "KHDN04",
    //                         "CreateAt": "2024-09-04T07:09:30.694Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.694Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661398287ff9fec8f9b909c",
    //                 "Name": "Tiền vay",
    //                 "GroupCode": "KHDN05",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.6Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.6Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615f3f87ff9fec8f9b90c2",
    //                         "Name": "Trả nợ vay từ tài khoản",
    //                         "ServiceType": "GDV_1032",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661398287ff9fec8f9b909c",
    //                         "GroupCode": "KHDN05",
    //                         "CreateAt": "2024-09-04T07:09:30.694Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.694Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "6661398e87ff9fec8f9b909d",
    //                 "Name": "Đăng ký dịch vụ",
    //                 "GroupCode": "KHDN06",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.6Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.6Z",
    //                 "Children": [
    //                     {
    //                         "ID": "66615f8c87ff9fec8f9b90c3",
    //                         "Name": "Dịch vụ NHĐT",
    //                         "ServiceType": "GDV_2162",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "6661398e87ff9fec8f9b909d",
    //                         "GroupCode": "KHDN06",
    //                         "CreateAt": "2024-09-04T07:09:30.694Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.695Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "66613d9387ff9fec8f9b909e",
    //                 "Name": "Ngoại tệ",
    //                 "GroupCode": "KHDN07",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.6Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.6Z",
    //                 "Children": [
    //                     {
    //                         "ID": "6661616187ff9fec8f9b90c4",
    //                         "Name": "Chuyển tiền ngoại tệ",
    //                         "ServiceType": "GDV_0542",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "66613d9387ff9fec8f9b909e",
    //                         "GroupCode": "KHDN07",
    //                         "CreateAt": "2024-09-04T07:09:30.695Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.695Z"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "ID": "66613da687ff9fec8f9b909f",
    //                 "Name": "Dịch vụ khác",
    //                 "GroupCode": "KHDN08",
    //                 "Description": "",
    //                 "Status": "A",
    //                 "CreateAt": "2024-09-10T03:19:53.6Z",
    //                 "UpdateAt": "2024-09-10T03:19:53.6Z",
    //                 "Children": [
    //                     {
    //                         "ID": "6661617987ff9fec8f9b90c5",
    //                         "Name": "Dịch vụ khác",
    //                         "ServiceType": "GDV_0002",
    //                         "Description": "",
    //                         "Status": "A",
    //                         "ParentID": "66613da687ff9fec8f9b909f",
    //                         "GroupCode": "KHDN08",
    //                         "CreateAt": "2024-09-04T07:09:30.695Z",
    //                         "UpdateAt": "2024-09-04T07:09:30.695Z"
    //                     }
    //                 ]
    //             }
    //         ]
    //     }
    //   }

      const { data } = res;
      dispatch({
        type: types.GET_ALL_COUNTER_SERVICES,
        listService: {
          personCustomer: data.PersonCustomer ? data.PersonCustomer : [],
          corpCustomer: data.CorpCustomer ? data.CorpCustomer : []
        }
      })
      //---
      onCompletedFunc && onCompletedFunc({
        code: 0,
        result: {
          personCustomer: data.PersonCustomer ? data.PersonCustomer : [],
          corpCustomer: data.CorpCustomer ? data.CorpCustomer : []
        }
      })
    }
    catch (err) {
      catchErrorApi(
        err,
        dispatch,
        types.GET_ALL_COUNTER_SERVICES_ERR,
        () => false
      )
    }
    //---
    removeLoading()
}

export const getCounterServiceConfig = ({uid, onCompleted}) => async (dispatch) => {
    const queryData = JSON.stringify({
        UID: uid
    })

    try {
        dispatch(addLoading())
        //---
        const res = await Axios.post(apiGetCounterServiceConfig, queryData);
        const { data } = res;
        if (data.Code !== 0) {
            onCompleted && onCompleted({
                code: data.Code,
                message: data.Message,
                result: []
            })
        } else {
            let dataSetting = {
                statusList: []
            }
            //---
            if(isArray(data.StatusList)){
                dataSetting.statusList = data.StatusList.map((item) => {
                    return {
                        code: item.Code,
                        value: item.Value
                    }
                })
            }
            //---
            if(dataSetting.statusList.length === 0){
                dataSetting.statusList = [
                    {
                        code: 'A',
                        value: 'Đang hoạt động'
                    },
                    {
                        code: 'INA',
                        value: 'Không hoạt động'
                    }
                    
                ]
            }
            //---
            onCompleted && onCompleted({
                code: 0,
                result: dataSetting
            })
        }
       
    } catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.GET_COUNTER_SERVICE_CONFIG_ERR,
            handleError400
        )
    }
    //---
    dispatch(removeLoading())
}

export const addGroupCounterService = ({dataInfo, onCompleted}) => async dispatch => {
    const dataReq = JSON.stringify({
        Name: dataInfo.name,
        Status: dataInfo.status,
        Description: dataInfo.description,
        GroupCode: dataInfo.serviceCode,
        Type: dataInfo.type
    })
    console.log(`addGroupCounterService: dataReq => ${dataReq}`);
    try {
        const res = await Axios.post(apiAddGroupCounterService, dataReq);
        const { data } = res;
        onCompleted && onCompleted({
            code: data.Code,
            message: data.Message
        })
    }
    catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.CREATE_GROUP_COUNTER_SERVICE_ERR,
            handleError400
        )
    }
}

export const updateGroupCounterService = ({dataInfo, onCompleted}) => async dispatch => {
    const dataReq = JSON.stringify({
        ID: dataInfo.id,
        Name: dataInfo.name,
        Status: dataInfo.status,
        Description: dataInfo.description,
        GroupCode: dataInfo.serviceCode
    })
    console.log(`updateGroupCounterService: dataReq => ${dataReq}`);
    
    try {
        const res = await Axios.post(apiUpdateGroupCounterService, dataReq);
        const { data } = res;
        onCompleted && onCompleted({
            code: data.Code,
            message: data.Message
        })
    }
    catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
        //---
        catchErrorApi(
            err,
            dispatch,
            types.UPDATE_GROUP_COUNTER_SERVICE_ERR,
            handleError400
        )
    }
}

export const addCounterService = ({dataInfo, onCompleted}) => async dispatch => {
    const dataReq = JSON.stringify({
        Name: dataInfo.name,
        ServiceCode: dataInfo.serviceCode,
        Status: dataInfo.status,
        Description: dataInfo.description,
        GroupCode: dataInfo.groupCode
    })
    console.log(`addCounterService: dataReq => ${dataReq}`);
    try {
        const res = await Axios.post(apiAddCounterService, dataReq);
        const { data } = res;
        onCompleted && onCompleted({
            code: data.Code,
            message: data.Message
        })
    }
    catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
    
        catchErrorApi(
            err,
            dispatch,
            types.CREATE_COUNTER_SERVICE_ERR,
            handleError400
        )
    }
}

export const updateCounterService = ({dataInfo, onCompleted}) => async dispatch => {
    const dataReq = JSON.stringify({
        ID: dataInfo.id,
        Name: dataInfo.name,
        ServiceCode: dataInfo.serviceCode,
        Status: dataInfo.status,
        Description: dataInfo.description,
        GroupCode: dataInfo.groupCode
    })
    console.log(`updateCounterService: dataReq => ${dataReq}`);
    try {
        const res = await Axios.post(apiUpdateCounterService, dataReq);
        const { data } = res;
        onCompleted && onCompleted({
            code: data.Code,
            message: data.Message
        })
    }
    catch (err) {
        const handleError400 = () => {
            if (err.response.data.Code !== 0) {
                onCompleted && onCompleted({
                    code: err.response.data.Code,
                    message: err.response.data.Message,
                })
                return true;
            }
            return false;
        }
        //---
        catchErrorApi(
            err,
            dispatch,
            types.UPDATE_COUNTER_SERVICE_ERR,
            handleError400
        )
    }
}


